import React, { useState } from 'react';
import etrt_audiogram from '../img/etrt/Audiogram.png';
import etrt_audiology from '../img/etrt/Audiology.png';
import etrt_register from '../img/etrt/RegisterPatient.png';

import admin_shop from '../img/hardware-store/AdminShop.png';
import checkout from '../img/hardware-store/Checkout.png';
import customer_checkout from '../img/hardware-store/CustomerCheckout.png';

import cal_main from '../img/weekforward/cal-main.png';
import cal_update from '../img/weekforward/cal-update.png';
import cal_add from '../img/weekforward/cal-add.png';
import cal_login from '../img/weekforward/cal-login.png';
import cal_signup from '../img/weekforward/cal-signup.png';

import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import ProjectCard from '../components/ProjectCard';


const Projects = () => {

    const [projects, setProjects] = useState([ 
        {
            title: "Week Forward",
            images: [
                {
                    img: cal_main,
                    alt: "cal-main"
                },
                {
                    img: cal_update,
                    alt: "cal_update"
                },
                {
                    img: cal_add,
                    alt: "cal_add"
                },
                {
                    img: cal_login,
                    alt: "cal_login"
                },
                {
                    img: cal_signup,
                    alt: "cal_signup"
                }
            ],
            github_link:"https://github.com/kylebascomb/weekly-calendar",
            link:"http://weekforward.com/",
            body: "Week Forward is a project I created in order to delve into web development using the MERN (Mongodb, Express, React, Node) stack.\
            The goal of the project is to create a simple calendar to plan your schedule for the following week. The project includes a  \
            user system for signing up and logging in. It also includes an easy to use UI for quickly adding or editing events on your schedule. \
            Besides the MERN stack, some other prominent libraries used were axios for the frontend, momentjs for handling \
            dates, bcryptjs for encrypting passwords, and MaterialUI for styling. The project is hosted on AWS using an EC2 instance and nginx to \
            serve the frontend. Feel free to check the project out by clicking the green icon below."
        },
        {
            title: "Hardware Store",
            images: [
                {
                    img: admin_shop,
                    alt: "admin_shop"
                },
                {
                    img: checkout,
                    alt: "checkout"
                },
                {
                    img: customer_checkout,
                    alt: "customer_checkout"
                }
            ],
            github_link:"https://github.com/kylebascomb/CS152-hardware",
            body: "This was a simple e-commerce front end application to demonstrate Object Oriented Programming Principles as a final project for \
            CS152 Programming Paradigms. This project was done over the course of 3 months and is written entirely in Java using the JavaFX library \
            for creating the GUI. We used an MVC pattern to design the application and applied Object Oriented programming practices such as Polymorphism \
            and Inheritance to create the view components for the shop.  "
        },
        {
            title: "eTRT",
            images: [
                {
                    img: etrt_audiogram,
                    alt: "etrt-audiogram"
                },
                {
                    img: etrt_audiology,
                    alt: "etrt-audiology"
                },
                {
                    img: etrt_register,
                    alt: "etrt-register"
                }
            ],
            body: "I created prototype front-end in Java Swing for an patient management system for audiology. \
            This project was made to demonstrate skills with Object Oriented programming design patterns and Model View Controller\
             applications in Java. Connects to AI / ML database for predictive decision support."
        },
    ]);

    return (
        <Container maxWidth="lg">
            <Box p={2} />
            <Typography variant="h1">Projects</Typography>
            <Box>
                {projects.map((project) => (
                    <Box p={2}>
                        <ProjectCard project={project} />
                    </Box>
                ))}
                
            </Box>
        </Container>
    );
    
};
export default Projects;