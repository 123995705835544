import React, { Component } from 'react';
import {SocialIcon} from 'react-social-icons';
const Social = () => {
        return (
            
            <div >
                 <SocialIcon url="https://github.com/kylebascomb" target="_blank"/>
                <SocialIcon url="https://www.linkedin.com/in/kylebascomb/" target="_blank"/>
                <SocialIcon url= "mailto:kylebascomb@gmail.com" network = "email" target="_blank"/> 
            </div>
        );

};
export default Social;