import React, { useState } from 'react';
import CenterImage from '../components/CenterImage';
import nasa_cert from '../img/Nasa/nasa_genelab_cert.jpg';
import icorps_cert from '../img/etrt/august_sprint_cert.jpg';
import kis_logo from '../img/kis/kis_logo.jpg'

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import Divider from '@material-ui/core/Divider';
import ExperienceCard from '../components/ExperienceCard';

const Experience = () => {

    const [experience, setExperience] = useState([
        {
            title: "KIS Computer - Inside Sales Operations",
            img: kis_logo,
            alt: "kis_logo",
            dates:"August 2019 - Present",
            body: "At KIS, my primary role is to increase the productivity of the Sales team. I have helped with various automations \
            and integrations for the Inside Sales team. Some of my responsibilities include:",
            bulletPoints:["Creating reports with various sales tools such as Hubspot, SAP, and Autotask to increase productivity for the sales team.",
                            "Cleaning and migrating data between tools using Python, SQL, and Excel.",
                            "Researching CMAS and GSA contracts for IT work.",
                            "Coordinating and setting up annual customer appreciation events for 2019 and 2020."]
        },
        {
            title: "NASA Genelab RNAseq Bootcamp",
            img: nasa_cert,
            alt: "nasa_cert",
            dates:"June 2021",
            body: "In the summer of 2021, I was part of the initial trial run of the NASA Genelab RNAseq Bootcamp. \
            Throughout the bootcamp, I learned how the RNAseq pipeline works at Genelab \
            for analyzing differential gene expression in experiments comparing ground control \
            tissue samples to tissue samples that flew in space. I used Unix commands, Python, and tools such as \
            STAR, RSEM, and DESeq2 to process the RNA seq data. Once the data was processed, the final step was to \
            use R to visualize and analyze the data.",
            bulletPoints:[""]
        },
        {
            title: "National Science Foundation I-Corps Team Member",
            img: icorps_cert,
            alt: "icorps_cert",
            dates:"August 2020",
            link: "https://www.nsf.gov/news/special_reports/i-corps/index.jsp",
            body: "In the summer of 2020, I particpated in the \
            NSF I-Corps August Sprint. \
            This was a biotech program to help develop projects. I participated in the program with \
            two professors at San José and a program called eTRT. In short, the program was a patient management system \
            for audiology. There's more details on the project in the Projects section. During the I-Corps program, we worked on \
            developing the direction of eTRT with the mentorship of experts in the biotech field and interviews with some \
            of the leading tinnitus experts in North America.",
            bulletPoints:[""]
        },
    ]);


    return (

        <Container
            maxWidth="lg"
        >
            <Box p={2} />
            <Typography variant="h1">Experience</Typography>
            <Box p={2}>
                {
                    experience.map((exp) => (
                        <ExperienceCard experience={exp}/>
                    ))
                }

            </Box>

        </Container>

    );
}
export default Experience