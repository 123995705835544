import profilepic from '../img/headshot/bascomb-headshot.jpg';
//import ReactTypingEffect from 'react-typing-effect';
import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import Divider from '@material-ui/core/Divider';

import Skills from './Skills';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    imageList: {

      height: 450,
    },
  }));

const Home = () => {

    const classes = useStyles();
    return (

        <Container
            maxWidth="lg"
        >
            <Box p={2}>
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                        <ImageList rowHeight={800} cols={2}>
                                <ImageListItem  cols={2}>
                                    <img src={profilepic} alt={"kylebascomb"} />
                                </ImageListItem>
                        </ImageList>
                    </Grid>
                    <Grid item >
                        <Typography variant='h1' align='center' gutterBottom>About Me</Typography>
                        <Typography variant='h2' align='center' >Kyle Bascomb</Typography>
                        <Typography variant='h4' align='center' gutterBottom>Engineer</Typography>
                        <Divider />
                        <Box p={2}/>
                        <Typography paragraph >
                            Im currently a senior in the Computer Science department at San José State University
                            set to graduate in December 2021. I've taken a variety of classes at SJSU including Operating Systems,
                            Programming Paradigms, Bioinformatics, Computer Networks, Object Oriented Programming, and plenty more. 
                        </Typography>
                        <Divider />
                        <Box p={2}/>
                        <Typography variant='h6' align='left'>Skills Overview</Typography>
                        <Skills />
             
                    </Grid>
                </Grid>
            </Box>

        </Container>

    );
};
export default Home;