import React, { Component, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import Divider from '@material-ui/core/Divider';
import { SocialIcon } from 'react-social-icons';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';



const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    imageList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
}));

const Project = (props) => {

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClick = (img) => {
        setAnchorEl(img);
    };

    const handleClose = (e) => {
        setAnchorEl(null);
        e.stopPropagation();
    };

    return (

        <Card >

            <CardContent >
                <Box p={3}>
                    <Typography variant="h3">{props.project.title}</Typography>
                    <Typography variant="h3">{props.project.subtitle}</Typography>
                    <Typography variant="h3">{props.project.date}</Typography>
                    <ImageList rowHeight={400} className={classes.imageList} cols={2.2}>
                        {props.project.images.map((item) => (
                            <ImageListItem key={item.img} >
                                <img src={item.img} alt={item.title} onClick={() => handleClick(item.img)}/>
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'center',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'center',
                                        horizontal: 'center',
                                    }}
                                >
                                    <Container maxWidth="80vh">
                                        <img src={anchorEl} alt={item.title} />
                                    </Container>
                                </Popover>
                            </ImageListItem>
                        ))}
                    </ImageList>
                    <Typography paragraph>{props.project.body}</Typography>
                    {props.project.github_link &&
                        <SocialIcon url={props.project.github_link} target="_blank" />
                    }
                    {props.project.link &&
                        <SocialIcon url={props.project.link} target="_blank" />
                    }
                </Box>

            </CardContent>
        </Card>

    );
};

export default Project;