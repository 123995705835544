import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    dividerFullWidth: {
      margin: `5px 0 0 ${theme.spacing(2)}px`,
    },
    dividerInset: {
      margin: `5px 0 0 ${theme.spacing(9)}px`,
    },
  }));

const Skills = (props) => {

    const classes = useStyles();

    const [skills, setSkills] = useState({
        'myskills': ['Java', 'Python', 'Git', 'Linux'],
        'learningskills': ['Jira', 'Docker',
           'Data Science: Numpy, seaborn, pandas, scikitlearn, Jupyter Lab, Tensorflow, keras',
            'Web Development: JavaScript, Mongodb, ExpressJS, ReactJS, NodeJS, HTML5, CSS, MaterialUI',
            'AWS: Route53, EC2, Amplify']
    });
    return(
        
          <Container component="main" maxWidth="md" class={classes.condiv}>
              <Box p={1}>
                  <Grid container wrap="nowrap" spacing={2}>
                      <List>
                          <ListItem class={classes.dividerFullWidth}>
                              My Top Technical Skills
                          </ListItem>
                        {skills.myskills.map(skill => (
                            <ListItem class={classes.dividerInset}>
                                <Typography >{skill}</Typography>
                            </ListItem>
                        )
                        )}
                      </List>
                 </Grid>
                 <Divider />
                 <Grid container wrap="nowrap" spacing={2}>
                 <List>
                          <ListItem class={classes.dividerFullWidth}>
                              Developing Skills
                          </ListItem>
                        {skills.learningskills.map(skill => (
                            <ListItem class={classes.dividerInset}>
                                <Typography >{skill}</Typography>
                            </ListItem>
                        )
                        )}
                      </List>
                 </Grid>
                 

                 
              </Box>
          </Container>


        //  <div className = "condiv skills" >
        //      <h1 className="subtopic">My Skills</h1>
        //      <h2 className="subtopic">Top Skills </h2>
        //      <ul>
        //         {skills.myskills.map((value) => {
        //              return <li>{value}</li>
        //          })}
        //      </ul>
        //      <h2 className="subtopic">Developing Skills </h2>
        //      <ul>
        //          {skills.learningskills.map((value) => {
        //              return <li>{value}</li>
        //          })}
        //      </ul>
        //  </div>
    );

};

export default Skills;