import React, { Component } from 'react';
import CenterImage from '../components/CenterImage';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';


const ExperienceCard = (props) => {


    return (
        <Box p={2}>
            <Card>
                <CardContent>
                    <Box p={3}>
                        <Grid container wrap="nowrap" spacing={2}>
                            <Grid item xs={12}>
                                <ImageList rowHeight={400} cols={1}>
                                    <ImageListItem cols={1}>
                                        <img src={props.experience.img} alt={props.experience.alt} />
                                    </ImageListItem>
                                </ImageList>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='h3' align='center' gutterBottom>{props.experience.title}</Typography>
                                <Typography variant='h4' align='center' >{props.experience.dates}</Typography>
                                <Divider />
                                <Box p={2} />
                                <Typography paragraph >
                                    {props.experience.body}
                                </Typography>
                                <List>
                                    {
                                        props.experience.bulletPoints.map((bp) => (
                                            <ListItem>
                                                <ListItemText primary = {bp}/>           
                                            </ListItem>
                                        ))
                                    }
                                </List>


                            </Grid>

                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
}
export default ExperienceCard;