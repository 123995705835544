import React, { useState } from 'react';
import CenterImage from '../components/CenterImage';
import sjsu_logo from '../img/colleges/sjsu.png'
import deanza_logo from '../img/colleges/deanza.png'

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import Divider from '@material-ui/core/Divider';
import EducationCard from '../components/EducationCard';

const Education = () => {

    const [education, setEducation] = useState([
        {
            title: "San José State University",
            img: sjsu_logo,
            alt: "sjsu_logo",
            startDate:"August 2019",
            endDate:"December 2021",
            major: "B.S. Computer Science",
            gpa:"3.9",
            body: ""
        },
        {
            title: "De Anza Community College",
            img: deanza_logo,
            alt: "deanza_logo",
            major: "A.S.T Computer Science",
            startDate:"2017",
            endDate:"2019",
            gpa:"3.9",
            body: ""
        },
        
    ]);


    return (

        <Container
            maxWidth="lg"
        >
            <Box p={2} />
            <Typography variant="h1">Education</Typography>
            <Box p={2}>
                {
                    education.map((edu) => (
                        <EducationCard education={edu}/>
                    ))
                }

            </Box>

        </Container>

    );
}
export default Education