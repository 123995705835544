import React from 'react';
import Skills from './contents/Skills';
import Contact from './contents/Contact';
import Home from './contents/Home';
import Projects from './contents/Projects'
import Education from './contents/Education'
import Experience from './contents/Experience'
import Navbar from './components/Navbar';
import Box from '@material-ui/core/Box';
import {
  BrowserRouter as Router,
  Route, Switch
} from "react-router-dom";

import '@fontsource/roboto';


const App = () => {
  return (
    <Box bgcolor='#f0f0f0' >
    <Router>
      <div className="App">
        <Navbar />
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route path="/experience" component={Experience}/>
          <Route path="/projects" component={Projects}/>
          <Route path="/education" component={Education}/>
        </Switch>
      </div>
    </Router >
    </Box>

  );
}

export default App;