
import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Social from './Social';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import { flexbox } from '@material-ui/system';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        marginRight: theme.spacing(6),
    },
}));


const Navbar = () => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar >            
                        <Button align="left" edge="start" color="inherit"  href="/" >
                            <Typography variant="h6" className={classes.title} >Kyle Bascomb</Typography>
                        </Button>
                    
                        <Button variant="outlined" color="inherit" className={classes.menuButton} href="/experience" >
                            Experience
                        </Button>
                        <Button variant="outlined" color="inherit" className={classes.menuButton} href="/projects" >
                            Projects
                        </Button>
                        <Button variant="outlined" color="inherit" className={classes.menuButton} href="/education" >
                            Education
                        </Button>
                        <Box style={{ flex: 1 }} />
                        <Social /> 

                        

                </Toolbar>
            </AppBar>
        </div>
    );
};

export default Navbar;
// class Navbar extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             'NavItemActive': ''
//         }
//     }

//     activeitem = (x) => {
//         if(this.state.NavItemActive != null){
//             if (this.state.NavItemActive.length > 0) {
//                 document.getElementById(this.state.NavItemActive).classList.remove('active');
//             }
//             this.setState({ 'NavItemId': x }, () => {
//                 document.getElementById(this.state.NavItemId).classList.add('active');
//             });
//         };

//     };


// render() {
//     return (
//         <nav>
//             <ul>
//                 <Navitem item="Home" tolink="/" activec={this.activeitem}></Navitem>
//                 <Navitem item="Experience" tolink="/experience" activec={this.activeitem}></Navitem>
//                 <Navitem item="Projects" tolink="/projects" activec={this.activeitem}></Navitem>
//                 <Navitem item="Education" tolink="/education" activec={this.activeitem}></Navitem>
//                 <Navitem item="Skills" tolink="/skills" activec={this.activeitem}></Navitem>
//                 <Navitem item="Contact" tolink="/contact" activec={this.activeitem}></Navitem>
//             </ul>
//         </nav>
//     )
// }
// }
// export default Navbar